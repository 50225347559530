import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material';
import NProgress from 'nprogress';

import useStyles from './LoadingScreen.styles';

const LoadingScreen: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return (): any => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
