import type { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import type {
  AdditionalText,
  AdditionalTextCategory,
  AggregatedTimeBooking,
  AutocompleteModel,
  BaseModelChoice,
  CheckIn,
  CheckInStatus,
  CheckOut,
  ConstructionProject,
  ConstructionProjectDetail,
  ContractorProject,
  ContractorProjectCategory,
  ContractorProjectChoice,
  ContractorProjectCreatePayload,
  ContractorProjectDetail,
  ContractorProjectStatus,
  ContractorProjectUpdatePayload,
  CurrentUser,
  DailyTurnover,
  DayLaborBooking,
  DayLaborTeamBooking,
  EditUserPayload,
  EmployeeCreatePayload,
  EmployeeStatus,
  EmployeeUpdatePayload,
  ExportModelField,
  FileCaptureType,
  FileEntity,
  Kpi,
  LegalEntityUser,
  PhotoCategory,
  ProjectConnection,
  ProjectNote,
  ProjectNoteCreatePayload,
  ProjectNoteUpdatePayload,
  RemarkText,
  Report,
  ReportCreatePayload,
  ReportKind,
  ReportStatus,
  Specification,
  Task,
  TaskDetail,
  TaskList,
  TaskProgress,
  TaskUpdatePayload,
  TeamDayLaborUpdatePayload,
  TeamTimeBooking,
  TimeBooking,
  TimeBookingAggregation,
  Trade,
} from 'src/types';
import { FileKind } from 'src/types';
import { Customer, CustomerKind, CustomerStatus } from 'src/types/customer';
import { ExportModel } from 'src/types/export';

import api from './axios';

interface EmptyResponse {}

export interface PaginatedResponse<T = any> {
  count: number | null;
  next: string | null;
  previous: number | null;
  results: T;
}

interface RequestProps {
  config: AxiosRequestConfig;
}

interface PaginatedRequestProps extends RequestProps {
  limit?: number;
  offset?: number;
  search?: string;
  ordering?: string;
}

function paginatedRequest<T, U = unknown>(requestUrl: string, requestProps: PaginatedRequestProps) {
  const { config, ...params } = requestProps;

  return api.get<PaginatedResponse<T> & U>(requestUrl, {
    ...config,
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
}

interface AggregatedTimeBookingsRequestProps extends PaginatedRequestProps {
  aggregateBy: TimeBookingAggregation;
  constructionProjects?: number | number[];
  contractorProjects?: number | number[];
  users?: number | number[];
  aggregateDateAfter?: string;
  aggregateDateBefore?: string;
}

export const aggregatedTimeBookingsRequest = (requestProps: AggregatedTimeBookingsRequestProps) =>
  paginatedRequest<AggregatedTimeBooking[]>('aggregated-time-bookings/', requestProps);

interface DailyTurnoverRequestProps extends PaginatedRequestProps {
  contractorProjects?: number | number[];
  constructionProjects?: number | number[];
  specifications?: number | number[];
  dateAfter: string;
  dateBefore: string;
}

export const dailyTurnoverRequest = (requestProps: DailyTurnoverRequestProps) => {
  const { ...params } = requestProps;
  return paginatedRequest<DailyTurnover[], { currencyCode: string }>('daily-turnover/', params);
};

interface CustomerRequestProps extends PaginatedRequestProps {
  kind?: CustomerKind;
  status?: CustomerStatus;
}

export const customersRequest = (requestProps: CustomerRequestProps) => {
  return paginatedRequest<Customer[]>('customers/', requestProps);
};

interface CustomerDetailsRequestProps extends RequestProps {
  id: number;
}

export const customerDetailRequest = (requestProps: CustomerDetailsRequestProps) => {
  const { id, config } = requestProps;
  return api.get<Customer>(`customers/${id}/`, config);
};

export interface CustomerCreateRequestProps
  extends RequestProps,
    Pick<
      Customer,
      | 'kind'
      | 'status'
      | 'companyName'
      | 'recipient'
      | 'firstName'
      | 'lastName'
      | 'address'
      | 'postalCode'
      | 'city'
      | 'country'
      | 'customerNumber'
      | 'email'
      | 'phoneNumber'
    > {}

export const customerCreateRequest = (requestProps: CustomerCreateRequestProps) => {
  const { config, ...payload } = requestProps;
  return api.post<Customer>('customers/', payload, config);
};

interface CustomerUpdateRequestProps extends CustomerCreateRequestProps {
  id: number;
}

export const customerUpdateRequest = (requestProps: CustomerUpdateRequestProps) => {
  const { config, id, ...payload } = requestProps;
  return api.patch<Customer>(`customers/${id}/`, payload, config);
};

interface CustomerDeleteRequestProps extends RequestProps {
  id: number;
}

export const customerDeleteRequest = (requestProps: CustomerDeleteRequestProps) => {
  const { config, id } = requestProps;
  return api.delete(`customers/${id}/`, config);
};

interface EmployeeRequestProps extends PaginatedRequestProps {
  legalEntityId: number;
  status?: string[];
}

export const employeeRequest = (requestProps: EmployeeRequestProps) => {
  const { legalEntityId } = requestProps;
  return paginatedRequest<EmployeeStatus[]>(`contractors/${legalEntityId}/user-status/`, requestProps);
};

interface EmployeeCreateRequestProps extends RequestProps {
  legalEntityId: number;
  employeeCreatePayload: EmployeeCreatePayload;
}

export const employeeCreateRequest = (requestProps: EmployeeCreateRequestProps) => {
  const { config, legalEntityId, employeeCreatePayload } = requestProps;

  return api.post<LegalEntityUser>(`legal-entities/${legalEntityId}/users/`, employeeCreatePayload, config);
};

interface EmployeeDeleteRequestProps extends RequestProps {
  legalEntityId: number;
  userId: number;
}

export const employeeDeleteRequest = (requestProps: EmployeeDeleteRequestProps) => {
  const { config, legalEntityId, userId } = requestProps;

  return api.delete(`legal-entities/${legalEntityId}/users/${userId}/`, config);
};

interface EmployeeUpdateRequestProps extends RequestProps {
  legalEntityId: number;
  userId: number;
  employeeUpdatePayload: EmployeeUpdatePayload;
}

export const employeeUpdateRequest = (requestProps: EmployeeUpdateRequestProps) => {
  const { config, legalEntityId, employeeUpdatePayload, userId } = requestProps;

  return api.patch<LegalEntityUser>(`legal-entities/${legalEntityId}/users/${userId}/`, employeeUpdatePayload, config);
};

interface KpisRequestProps extends RequestProps {
  legalEntityId: number;
}

export const kpisRequest = (requestProps: KpisRequestProps) => {
  const { config, legalEntityId, ...params } = requestProps;

  return api.get<Kpi>(`contractors/${legalEntityId}/kpis/`, { ...config, params: params });
};

//
// Construction Projects

interface ConstructionProjectChoicesRequestProps extends RequestProps {
  id?: number;
  search?: string;
}

export const constructionProjectChoicesRequest = (requestProps: ConstructionProjectChoicesRequestProps) => {
  const { config, ...params } = requestProps;

  return api.get<Pick<ConstructionProject, 'id' | 'name'>[]>('construction-projects/choices/', {
    ...config,
    params: params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

interface ConstructionProjectsRequestProps extends PaginatedRequestProps {
  status?: string[];
  checkInStatus?: string[];
  lastCheckedInAtStart?: string;
  lastCheckedInAtEnd?: string;
}

export const constructionProjectsRequest = (requestProps: ConstructionProjectsRequestProps) =>
  paginatedRequest<ConstructionProject[]>('construction-projects/', requestProps);

interface ConstructionProjectDetailRequestProps extends RequestProps {
  constructionProjectId: number | undefined;
}

export const constructionProjectDetailRequest = (requestProps: ConstructionProjectDetailRequestProps) => {
  const { config, constructionProjectId } = requestProps;

  return api.get<ConstructionProjectDetail>(`construction-projects/${constructionProjectId}/`, config);
};

interface CheckInDetailRequestProps extends RequestProps {
  checkInId: number;
}

export const checkInDetailRequest = (requestProps: CheckInDetailRequestProps) => {
  const { config, checkInId } = requestProps;
  return api.get<CheckIn>(`check-in/${checkInId}/`, config);
};

interface CheckOutDetailRequestProps extends RequestProps {
  checkOutId: number;
}

export const checkOutDetailRequest = (requestProps: CheckOutDetailRequestProps) => {
  const { config, checkOutId } = requestProps;
  return api.get<CheckOut>(`check-out/${checkOutId}/`, config);
};

//
// Project Connections

interface ProjectConnectionRequestProps extends PaginatedRequestProps {
  contractorProjects?: number | number[];
  hasContractorProject?: boolean;
}

export const projectConnectionsRequest = (requestProps: ProjectConnectionRequestProps) =>
  paginatedRequest<ProjectConnection[]>('project-connections/', requestProps);

//
// Contractor Projects
interface ContractorProjectChoicesRequestProps extends RequestProps {
  search?: string;
}

export const contractorProjectChoicesRequest = (requestProps: ContractorProjectChoicesRequestProps) => {
  const { config, ...params } = requestProps;

  return api.get<ContractorProjectChoice[]>('contractor-projects/choices/', {
    ...config,
    params: params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

interface ContractorProjectsRequestProps extends PaginatedRequestProps {
  category?: ContractorProjectCategory[] | ContractorProjectCategory;
  status?: ContractorProjectStatus[] | ContractorProjectStatus;
  checkInStatus?: CheckInStatus[] | CheckInStatus;
  constructionProjects?: number[] | number;
  contractors?: number[] | number;
}

export const contractorProjectsRequest = (requestProps: ContractorProjectsRequestProps) =>
  paginatedRequest<ContractorProject[]>('contractor-projects/', requestProps);

interface ContractorProjectCreateRequestProps extends RequestProps {
  projectCreatePayload: ContractorProjectCreatePayload;
}

export const contractorProjectCreateRequest = (requestProps: ContractorProjectCreateRequestProps) => {
  const { config, projectCreatePayload } = requestProps;

  return api.post('contractor-projects/', { ...projectCreatePayload }, config);
};

interface ContractorProjectUpdateRequestProps extends RequestProps {
  projectUpdatePayload: ContractorProjectUpdatePayload;
  contractorProjectId: number;
}

export const contractorProjectUpdateRequest = (requestProps: ContractorProjectUpdateRequestProps) => {
  const { config, contractorProjectId, projectUpdatePayload } = requestProps;

  return api.patch(`contractor-projects/${contractorProjectId}/`, { ...projectUpdatePayload }, config);
};

interface ContractorProjectDayLaborRequestProps extends PaginatedRequestProps {
  dateBefore?: string;
  dateAfter?: string;
  contractorProjectId: number;
}

export const contractorProjectDayLaborRequest = (requestProps: ContractorProjectDayLaborRequestProps) => {
  const { contractorProjectId } = requestProps;
  return paginatedRequest<DayLaborBooking[]>(`contractor-projects/${contractorProjectId}/day-labor/`, requestProps);
};

interface ContractorProjectTeamDayLaborRequestProps extends PaginatedRequestProps {
  contractorProjectId: number;
  teamDayLaborId: number;
}

export const contractorProjectTeamDayLaborRequest = (requestProps: ContractorProjectTeamDayLaborRequestProps) => {
  const { config, contractorProjectId, teamDayLaborId } = requestProps;
  const requestUrl = `/contractor-projects/${contractorProjectId}/team-day-labor/${teamDayLaborId}/`;

  return api.get<DayLaborTeamBooking>(requestUrl, config);
};

interface ContractorProjectTeamDayLaborUpdateProps extends RequestProps {
  contractorProjectId: number;
  teamDayLaborId: number;
  teamDayLaborUpdatePayload: TeamDayLaborUpdatePayload;
}

export const contractorProjectTeamDayLaborPartialUpdate = (requestProps: ContractorProjectTeamDayLaborUpdateProps) => {
  const { config, contractorProjectId, teamDayLaborId, teamDayLaborUpdatePayload } = requestProps;
  const requestUrl = `/contractor-projects/${contractorProjectId}/team-day-labor/${teamDayLaborId}/`;

  return api.patch<TeamDayLaborUpdatePayload>(requestUrl, teamDayLaborUpdatePayload, config);
};

interface ContractorProjectDetailRequestProps extends RequestProps {
  contractorProjectId: number;
}

export const contractorProjectDetailRequest = (requestProps: ContractorProjectDetailRequestProps) => {
  const { config, contractorProjectId } = requestProps;

  return api.get<ContractorProjectDetail>(`contractor-projects/${contractorProjectId}/`, config);
};

interface TaskProgressesRequest extends PaginatedRequestProps {
  contractorProjects?: number | number[];
  specifications?: number | number[];
  taskLists?: number | number[];
  tasks?: number | number[];
  users?: number | number[];
  createdBy?: number | number[];
}

export const taskProgressesRequest = (requestProps: TaskProgressesRequest) =>
  paginatedRequest<TaskProgress[]>('task-progresses/', requestProps);

interface TimeBookingsRequestProps extends PaginatedRequestProps {
  contractorProjects?: number | number[];
  users?: number | number[];
  createdBy?: number | number[];
  dateAfter?: string;
  dateBefore?: string;
}

export const timeBookingsRequest = (requestProps: TimeBookingsRequestProps) =>
  paginatedRequest<TimeBooking[]>('time-bookings/', requestProps);

interface TimeBookingRequestProps extends RequestProps {
  timeBookingId: number;
}
export const timeBookingRequest = ({ timeBookingId, config }: TimeBookingRequestProps) =>
  api.get<TimeBooking>(`time-bookings/${timeBookingId}/`, config);

interface TimeBookingDeleteRequestProps extends RequestProps {
  timeBookingId: number;
}

export const timeBookingDeleteRequest = ({ timeBookingId, config }: TimeBookingDeleteRequestProps) =>
  api.delete<TimeBooking>(`time-bookings/${timeBookingId}/`, config);

interface TimeBookingUpdateRequestProps extends RequestProps {
  timeBookingId: number;
  payload: Pick<TimeBooking, 'startedAt' | 'finishedAt' | 'journeyMinutes' | 'pauseMinutes'>;
}
export const timeBookingUpdateRequest = ({ timeBookingId, config, payload }: TimeBookingUpdateRequestProps) =>
  api.patch<TimeBooking>(`time-bookings/${timeBookingId}/`, payload, config);

interface TimeBookingCreateRequestProps extends RequestProps {
  payload: {
    startedAt: string;
    finishedAt: string;
    journeyMinutes: number;
    pauseMinutes: number;
    user: number;
    teamBooking: number;
  };
}
export const timeBookingCreateRequest = ({ config, payload }: TimeBookingCreateRequestProps) =>
  api.post<TimeBooking>(`time-bookings/`, payload, config);

interface TeamTimeBookingCreateRequestProps extends RequestProps {
  payload: {
    contractorProject: number;
  };
}
export const teamTimeBookingCreateRequest = ({ config, payload }: TeamTimeBookingCreateRequestProps) =>
  api.post<TeamTimeBooking>(`team-time-bookings/`, payload, config);

// Files
interface FilesRequestProps extends PaginatedRequestProps {
  kind: FileKind;
  contractorProjects?: number | number[];
  tasks?: number | number[];
  teamDayLaborSet?: number | number[];
  constructionProjects?: number | number[];
  trades?: Trade | Trade[];
  recordedAtAfter?: string;
  recordedAtBefore?: string;
}

export const filesRequest = (requestProps: FilesRequestProps) => {
  return paginatedRequest<FileEntity[]>('files/', requestProps);
};

interface FileDetailRequestProps extends RequestProps {
  fileId: number;
}

export const fileDetailRequest = (requestProps: FileDetailRequestProps) => {
  const { config, fileId } = requestProps;

  return api.get<FileEntity>(`files/${fileId}/`, config);
};

export type PhotosRequestProps = Omit<FilesRequestProps, 'kind'>;
export type PhotosRequestFilters = Omit<PhotosRequestProps, 'config'>;

// Just a convenience wrapper for `filesRequest`.
export const photosRequest = (requestProps: PhotosRequestProps) => {
  const props: FilesRequestProps = {
    ...requestProps,
    kind: FileKind.IMAGE,
  };
  return filesRequest(props);
};

export type DocumentsRequestProps = Omit<FilesRequestProps, 'kind'>;

// Just a convenience wrapper for `filesRequest`.
export const documentsRequest = (requestProps: DocumentsRequestProps) => {
  const props: FilesRequestProps = {
    ...requestProps,
    kind: FileKind.DOCUMENT,
  };
  return filesRequest(props);
};

export interface FileUploadRequestProps extends RequestProps {
  captureType?: FileCaptureType;
  category?: PhotoCategory;
  comment?: string;
  constructionProjects?: number | number[];
  contractorProjects?: number | number[];
  deviceInformation?: { [key: string]: any };
  file: File;
  groupId?: string | null;
  kind: FileKind;
  recordedAt?: string;
  tasks?: number | number[];
}

export const fileUploadRequest = (
  requestProps: FileUploadRequestProps,
  onUploadProgress: (event: AxiosProgressEvent) => void,
  controller: AbortController,
) => {
  const { config, file, deviceInformation, ...requestData } = requestProps;

  const formData = new FormData();
  formData.append('file', file);

  if (deviceInformation) {
    formData.append('deviceInformation', JSON.stringify(deviceInformation));
  }

  Object.keys(requestData).forEach((key) => {
    const value = requestData[key];

    if (Array.isArray(value)) {
      value.forEach((v) => formData.append(key, v.toString()));
    } else {
      value !== undefined && formData.append(key, value.toString());
    }
  });

  return api.post(`files/`, formData, {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
    signal: controller.signal,
  });
};

export interface FileUpdateRequestProps extends RequestProps {
  id: number;
  category: string;
  tasks: number | number[];
  contractorProjects: number | number[];
}

export interface FileUpdateResponse {
  category: FileEntity['category'];
  tasks: FileEntity['tasks'];
  comment: FileEntity['comment'];
}

export const fileUpdateRequest = (requestProps: FileUpdateRequestProps) => {
  const { config, id, ...payload } = requestProps;

  return api.patch<FileUpdateResponse>(`files/${id}/`, payload, config);
};

interface FileDeleteRequestProps extends RequestProps {
  fileId: number;
}

export const fileDeleteRequest = (requestProps: FileDeleteRequestProps) => {
  const { config, fileId } = requestProps;
  const requestUrl = `files/${fileId}/`;

  return api.delete<EmptyResponse>(requestUrl, config);
};

//
// Specifications, Task Lists, and Tasks

export interface SpecificationsRequestProps extends PaginatedRequestProps {
  contractorProjects?: number | number[];
}

export const specificationsRequest = (requestProps: SpecificationsRequestProps) =>
  paginatedRequest<Specification[]>('specifications/', requestProps);

interface SpecificationRequestProps extends RequestProps {
  specificationId: number;
}

export const specificationDetailRequest = (requestProps: SpecificationRequestProps) => {
  const { config, specificationId } = requestProps;

  return api.get<Specification>(`specifications/${specificationId}/`, config);
};

interface SpecificationDeleteRequestProps extends RequestProps {
  specificationId: number;
}

export const specificationDeleteRequest = (requestProps: SpecificationDeleteRequestProps) => {
  const { config, specificationId } = requestProps;

  return api.delete(`specifications/${specificationId}/`, config);
};

interface TaskListsRequestProps extends PaginatedRequestProps {
  specifications: number;
}

export const taskListsRequest = (requestProps: TaskListsRequestProps) =>
  paginatedRequest<TaskList[]>('task-lists/', requestProps);

interface TaskListRequestProps extends RequestProps {
  taskListId: number;
}

export const taskListDetailRequest = (requestProps: TaskListRequestProps) => {
  const { config, taskListId } = requestProps;

  return api.get<TaskList>(`task-lists/${taskListId}/`, config);
};

export interface TasksRequestProps extends PaginatedRequestProps {
  taskListsIncludingChildren?: number | number[];
  specifications?: number | number[];
}

export const tasksRequest = (requestProps: TasksRequestProps) => paginatedRequest<Task[]>('tasks/', requestProps);

interface TaskDetailRequestProps extends RequestProps {
  taskId: number;
}

export const taskDetailRequest = (requestProps: TaskDetailRequestProps) => {
  const { taskId, config } = requestProps;
  return api.get<TaskDetail>(`tasks/${taskId}/`, config);
};

export interface TaskUpdateRequestProps extends RequestProps {
  taskId: number;
  payload: TaskUpdatePayload;
}

export const taskUpdateRequest = (requestProps: TaskUpdateRequestProps) => {
  const { config, taskId, payload } = requestProps;

  return api.patch<TaskDetail>(`tasks/${taskId}/`, { ...payload }, config);
};

export interface AdditionalTextsRequestProps extends PaginatedRequestProps {
  specifications?: number | number[];
  category?: AdditionalTextCategory;
}

export const additionalTextsRequest = (requestProps: AdditionalTextsRequestProps) =>
  paginatedRequest<AdditionalText[]>('additional-texts/', requestProps);

interface TasksRemarkTextRequestProps extends PaginatedRequestProps {
  tasks?: number | number[];
}

interface TaskListsRemarkTextRequestProps extends PaginatedRequestProps {
  taskLists?: number | number[];
}

export const remarkTextsRequest = (requestProps: TasksRemarkTextRequestProps | TaskListsRemarkTextRequestProps) =>
  paginatedRequest<RemarkText[]>('remark-texts/', requestProps);

interface UploadX84RequestProps extends RequestProps {
  file: File;
  specificationId: number;
}

export const uploadX84Request = (requestProps: UploadX84RequestProps) => {
  const { config, file, specificationId } = requestProps;
  const formData = new FormData();

  formData.append('gaebFile', file);

  return api.patch(`specifications/${specificationId}/prices/`, formData, {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface UploadSpecificationRequestProps extends RequestProps {
  gaebFile: File;
  contractorProject: number;
}

export const uploadSpecificationRequest = (
  requestProps: UploadSpecificationRequestProps,
  onUploadProgress: (event: AxiosProgressEvent) => void,
  controller: AbortController,
) => {
  const { config, gaebFile, contractorProject } = requestProps;
  const formData = new FormData();

  formData.append('gaebFile', gaebFile);

  return api.post(`contractor-projects/${contractorProject}/import-gaeb-specification/`, formData, {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
    signal: controller.signal,
  });
};

interface UsersRequestProps extends PaginatedRequestProps {
  legalEntityId: number;
}

export const usersRequest = (requestProps: UsersRequestProps) => {
  const { legalEntityId } = requestProps;
  return paginatedRequest<LegalEntityUser[]>(`legal-entities/${legalEntityId}/users/`, requestProps);
};

// To fetch a single user
// Note: this is not used to fetch the logged in user
// Fetching the logged in user is handled by UserStore
interface UserRequestProps extends RequestProps {
  legalEntityId: number;
  userId: number;
}

export const userRequest = (requestProps: UserRequestProps) => {
  const { config, legalEntityId, userId } = requestProps;

  return api.get<LegalEntityUser>(`legal-entities/${legalEntityId}/users/${userId}/`, config);
};

interface UpdateUserProps extends RequestProps {
  editUserPayload: EditUserPayload;
}

export const updateUserRequest = (requestProps: UpdateUserProps) => {
  const { config, editUserPayload } = requestProps;
  const formData = new FormData();

  if (editUserPayload.avatar !== undefined) {
    formData.append('avatar', editUserPayload.avatar);
  }

  if (editUserPayload.email) {
    formData.append('email', editUserPayload.email);
  }
  if (editUserPayload.password) {
    formData.append('password', editUserPayload.password);
  }

  formData.append('firstName', editUserPayload.firstName);
  formData.append('lastName', editUserPayload.lastName);
  formData.append('phoneLandline', editUserPayload.phoneLandline);
  formData.append('phoneMobile', editUserPayload.phoneMobile);

  return api.patch<EditUserPayload>('/users/me/', formData, {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

interface NotesRequestProps extends PaginatedRequestProps {
  contractorProjects?: number | number[];
}

export const notesOverviewRequest = (requestProps: NotesRequestProps) =>
  paginatedRequest<ProjectNote[]>('project-notes/', requestProps);

interface NotesDeleteRequest extends RequestProps {
  noteId: number;
}

export const notesDeleteRequest = (requestProps: NotesDeleteRequest) => {
  const { config, noteId } = requestProps;
  const requestUrl = `project-notes/${noteId}/`;

  return api.delete(requestUrl, config);
};

interface NoteCreateRequestProps extends RequestProps {
  noteCreatePayload: ProjectNoteCreatePayload;
}

export const noteCreateRequest = (requestProps: NoteCreateRequestProps) => {
  const { config, noteCreatePayload } = requestProps;

  return api.post('project-notes/', { ...noteCreatePayload }, config);
};

interface NoteDetailRequestProps extends RequestProps {
  noteId: number;
}

export const noteDetailRequest = (requestProps: NoteDetailRequestProps) => {
  const { config, noteId } = requestProps;

  return api.get(`project-notes/${noteId}/`, config);
};

interface NoteUpdateRequestProps extends RequestProps {
  noteUpdatePayload: ProjectNoteUpdatePayload;
  noteId: number;
}

export const noteUpdateRequest = (requestProps: NoteUpdateRequestProps) => {
  const { config, noteId, noteUpdatePayload } = requestProps;

  return api.patch(`project-notes/${noteId}/`, { ...noteUpdatePayload }, config);
};

interface BaseModelChoicesRequestProps {
  withDeleted?: 'yes' | 'no';
}

interface ModelChoicesRequestProps extends BaseModelChoicesRequestProps, PaginatedRequestProps {
  modelLabel: AutocompleteModel;
}

export const modelChoicesRequest = <T extends BaseModelChoice>(requestProps: ModelChoicesRequestProps) =>
  paginatedRequest<T[]>('models/choices/', requestProps);

interface ModelChoicesForIdsRequestProps extends BaseModelChoicesRequestProps, RequestProps {
  modelLabel: AutocompleteModel;
  ids: string[];
}

export const modelChoicesForIdsRequest = <T extends BaseModelChoice>(requestProps: ModelChoicesForIdsRequestProps) => {
  const { config, ...params } = requestProps;
  return api.get<T[]>('models/choices/ids/', {
    ...config,
    params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

interface ModelExportFieldsRequestProps extends RequestProps {
  modelLabel: ExportModel;
}
export const modelExportFieldsRequest = (requestProps: ModelExportFieldsRequestProps) => {
  const { config, ...params } = requestProps;
  return api.get<ExportModelField[]>('models/export/fields/', {
    ...config,
    params,
  });
};

interface ReportsRequestProps extends PaginatedRequestProps {
  status?: ReportStatus | ReportStatus[];
  kind?: ReportKind | ReportKind[];
  dateAfter?: string;
  dateBefore?: string;
  createdAtAfter?: string;
  createdAtBefore?: string;
  createdBy?: number | number[];
  contractorProjects?: number | number[];
}

export const reportsRequest = (requestProps: ReportsRequestProps) =>
  paginatedRequest<Report[]>('reports/', requestProps);

interface ReportDetailRequestProps extends RequestProps {
  reportId: string;
}

export const reportDetailRequest = (requestProps: ReportDetailRequestProps) => {
  const { config, reportId } = requestProps;

  return api.get<Report>(`reports/${reportId}/`, config);
};

interface ReportCreateRequestProps extends RequestProps {
  payload: ReportCreatePayload;
}

export const reportCreateRequest = (requestProps: ReportCreateRequestProps) => {
  const { config, payload } = requestProps;

  return api.post('reports/', { ...payload }, config);
};

interface ReportDeleteRequestProps extends RequestProps {
  reportId: string;
}

export const reportDeleteRequest = (requestProps: ReportDeleteRequestProps) => {
  const { config, reportId } = requestProps;

  return api.delete(`reports/${reportId}/`, config);
};

interface ReportRetryRequestProps extends RequestProps {
  reportId: string;
}

export const reportRetryRequest = (requestProps: ReportRetryRequestProps) => {
  const { config, reportId } = requestProps;

  return api.post<Report>(`reports/${reportId}/retry/`, {}, config);
};

export interface CsrfResponse {
  csrf: string;
}

export interface MeResponse extends CsrfResponse {
  user: CurrentUser | null;
}

interface LoginRequestProps extends RequestProps {
  email: string;
  password: string;
}

export const loginRequest = (requestProps: LoginRequestProps) => {
  const { email, password, config } = requestProps;

  return api.post<MeResponse>('session-auth/login/', { email, password }, config);
};

export const meRequest = (requestProps: RequestProps) => {
  const { config } = requestProps;

  return api.get<MeResponse>('session-auth/me/', config);
};

export const logoutRequest = (requestProps: RequestProps) => {
  const { config } = requestProps;

  return api.post<CsrfResponse>('session-auth/logout/', {}, config);
};
