import type { ComponentProps, FC } from 'react';
import React, { useState } from 'react';

interface ImageProps extends ComponentProps<'img'> {}

const Image: FC<ImageProps> = ({ alt, src, ...props }) => {
  const [imageSource, setImageSource] = useState<string>(src);

  const handleImageLoadingError = () => {
    setImageSource(`${process.env.PUBLIC_URL + '/static/images/placeholder.png'}`);
  };

  return (
    <img
      alt={alt} // Fixes warning for missing alt property on the img-tag. It is included within the ImageProps-Spread though.
      crossOrigin="anonymous"
      src={imageSource}
      onError={handleImageLoadingError}
      {...props}
    />
  );
};

export default Image;
