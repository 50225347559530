import { TFunction } from 'i18next';

import i18n from 'src/services/i18n/i18n';

/**
 * A function that converts bytes to human readable megabytes, kilobytes, etc.
 */
export const formatBytes = (bytes: number, t: TFunction) => {
  if (bytes === 0) return '0' + ' ' + t('General.fileSizes.b');

  const k = 1000;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const formattedAmount = Intl.NumberFormat(i18n.language).format(parseFloat((bytes / Math.pow(k, i)).toFixed(2)));

  return formattedAmount + ' ' + t(`General.fileSizes.${sizes[i]}`);
};

/**
 * @description Takes a string with several words or an array of strings and returns the first letter
 * of the first word and the first letter of the last word. If there are no spaces to sperate name parts
 * it will return the first two letters of the string.
 * https://stackoverflow.com/a/61651662/9615479
 *
 * eg. John William Doe -> JD
 * eg. JohnWilliamDoe -> JO
 * eg. ["John William", "van Doe"] -> JD
 */
export const getInitials = (nameParts: string[] | string): string => {
  const fullName = Array.isArray(nameParts) ? nameParts.join(' ') : nameParts;

  const FirstName = fullName.split(' ')[0];
  const LastName = fullName.split(' ').reduceRight((a) => a);

  // Extract the first two characters of a string
  if (FirstName === LastName) {
    return FirstName.trim().substring(0, 2).toUpperCase();
  }

  // Abbreviate FirstName and LastName
  return [FirstName, LastName]
    .map((name) => name[0])
    .join('')
    .toUpperCase();
};

/**
 * Returns a human readable string for the given latitude or longitude values.
 * The values are fixed to 7 digits, which represents the pratical limitations of GPS.
 */
export const formattedCoordinates = (latitude: number, longitude: number) => {
  return `${latitude.toFixed(7)}, ${longitude.toFixed(7)}`;
};
