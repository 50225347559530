export enum Role {
  PLATFORM_MAINTAINER = 0,
  INVESTOR = 1,
  GENERAL_CONTRACTOR = 2,
  CONTRACTOR = 3,
}

export interface LegalEntity {
  role: Role;
  id: number;
  name: string;
  street?: string;
  streetNumber?: string;
  postalCode?: string;
  city?: string;
  logo?: null | string;
}
