import React, { FC } from 'react';
import { Avatar, BoxProps } from '@mui/material';
import clsx from 'clsx';

import { TestIDs } from 'src/testIDs';
import { CurrentUser, LegalEntityUser, User } from 'src/types';
import { getInitials } from 'src/utils/formatting';

import useStyles from './UserInfoAvatar.styles';

interface UserInfoAvatarProps extends BoxProps {
  className?: string;
  size?: 'small' | 'normal';
  user: User | CurrentUser | LegalEntityUser;
}

/**
 * Shows the user's avatar, if available. Otherwise it shows the initials.
 */
export const UserInfoAvatar: FC<UserInfoAvatarProps> = ({ user, className, size = 'normal' }) => {
  const classes = useStyles();
  const initials = user.fullName ? getInitials(user.fullName) : '';

  return (
    <Avatar
      data-test-id={TestIDs.components.userInfo.avatar}
      className={clsx([classes.main, size === 'small' ? classes.small : classes.normal, className])}
      src={user.avatar}
      title={user.fullName || user.legalEntity.name}
      alt={user.fullName || user.legalEntity.name}
    >
      {initials}
    </Avatar>
  );
};

export default UserInfoAvatar;
